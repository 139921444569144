import React, { useState , useEffect} from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';
import FlipperCard from '../../components/FlipperCard/FlipperCard';

import Aos from 'aos';
import 'aos/dist/aos.css';

import img_leistung_2 from '../../images/leistungen/img2.jpg';
import img_leistung_4 from '../../images/leistungen/img4.jpg';
import img_leistung_8 from '../../images/leistungen/img8.jpg';
import img_leistung_14 from '../../images/leistungen/img14.jpg';
import img_leistung_17 from '../../images/leistungen/img17.jpg';
import img_leistung_24 from '../../images/leistungen/img24.jpg';

import imageZweitmeinung from '../../images/Operationen-Zweitmeinung (unsplash)-min.jpg';

const LeistungenPage = () => {

  useEffect(() => {
      Aos.init({duration: 2000 });
  }, []);

  return(
  <Layout headerWhite>
      <SEO title="Leistungen | Dr. Kostka - integrative Medizin und Ästhetik" description="Dr. K. Kostka bietet integrative Medizin und Ästhetik mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓" />
    {/*<div className="greenContainer">
      <ul>
        <li className="active"><Link to="/leistungen/aesthetik">Ästhetik</Link></li>
        <li><Link to="/leistungen/schmerz">Schmerz</Link></li>
        <li><Link to="/leistungen/ernaehrung">Ernährung</Link></li>
      </ul>
    </div>*/}
    {/* Option dunkeler Hintergrund LLOPT
    <Section dark>
      <Container>
        <Headline h1 light subtitle="Dr. Kathrin Kostka">Ästhetik</Headline> */}
    <Section>
      <Container>
        <Headline h1 >Ästhetik</Headline>
        {/*<Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
              Botulinumtoxin  -
                {' '}
                <i>Botox©</i>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
              Wir haben eine natürliche Mimik wie Stirnrunzeln, Grübeln oder Blinzeln bei Sonneneinstrahlung. Im Laufe der Jahre führt dieser ständige Muskelzug anfangs zu Linien, später entstehen Falten.
              <br/>
              <br/>
              Mit Botox© können diese mimischen Falten der oberen Gesichtshälfte geglättet werden.
              <br/>
              <br/>
              Mit einer sehr feinen Nadel spritze ich das Botox© in den betreffenden Gesichtsmuskel, dessen Aktivität so eingeschränkt wird: Falten verschwinden, Gesichtszüge entspannen sich und man wirkt weniger gestresst und zornig.
              
              <br/>
              <br/>
              Mein oberstes Prinzip ist der Erhalt des natürlichen Ausdruckes – durch eine zurückhaltende Dosierung.
              <br/>
              <br/>
              In einem persönlichen Gespräch werden wir ausführlich alle möglichen Therapieoptionen und eventuelle Begleiterscheinungen besprechen.
              <br/>
              <br/>
              Der Effekt ist nach wenigen Tagen sichtbar und hält in der Regel 3-4 Monate an. Eine Auffrischung ist danach möglich.
              <br/>
              <br/>
              Wo kommt Botulinumtoxin zum Einsatz?
              <ul>
                <li>Zornesfalte</li>
                <li>Krähenfüße (seitliche Augenfalten)</li>
                <li>Stirnfalte</li>
                <li>Bunny line </li>
              </ul>
              Übersicht zur Behandlung mit Botulinumtoxin:
              <ul>
                <li>Schmerzen: sehr gering </li>
                <li>Gesellschaftsfähig: in der Regel sofort</li>
                <li>Sichtbares Ergebnis: nach 2-4 Tagen, Höhepunkt der Wirkung nach 10-14 Tagen</li>
                <li>Wirkdauer: 4-6 Monate</li>
              </ul>
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

              Hyperhidrosis   -
                {' '}
                <i>Endlich weniger schwitzen </i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
              Schwitzen ist ein natürlicher, lebensnotwendiger Prozess der Wärmeregulation des Körpers.
              Die Hyperhidrose bezeichnet ein unkontrollierbares, übermäßiges Schwitzen. Diese Fehlfunktion betrifft vorrangig die Schweißdrüsen von Achselhöhlen und Handinnenflächen/Fußsohlen.
              <br/>
              <br/>
              Da die Schweißproduktion nerval gesteuert wird, kann eine Behandlung mit Botox© diese sehr effektiv reduzieren. Botox© hemmt die Reizübertragung von der  Nervenzelle zur Schweißdrüse. 
              <br/>
              <br/>
              Die Injektionen erfolgen mit einer sehr feinen Nadel unter die Haut in den Achselhöhlen und sind kaum schmerzhaft. Der maximale Effekt tritt erst nach einigen Tagen ein. Das Ergebnis hält durchschnittlich 6 bis 12 Monate an. Um die Wirkung zu erhalten, sollte dementsprechend nach dieser Zeit eine Auffrischung erfolgen.              
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

              Hyaluronsäure  -
                {' '}
                <i>Filler</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
              Mit dem Alter verliert die Gesichtshaut Kollagen, Fett, Elastin und Hyaluron. Es entsteht ein Volumenverlust und ein Nachlassen der Spannkraft.
              <br/>
              <br/>
              Hyaluronsäure ist ein wasserbindender und körpereigener Bestandteil der Haut. Durch die Unterspritzung mit Hyaluronsäure entsteht eine Hebeeffekt der Falten und Gesichtsareale. So können Konturen wiederhergestellt und eine Straffung des Gesichts erzielt werden. Der Behandlungseffekt ist sofort sichtbar und minimal-invasiv.
              <br/>
              <br/>
              Entscheidend ist immer ein natürliches Ergebnis. In einem eingehenden Beratungsgespräch gehe ich sehr gerne auf ihre Vorstellungen und Wünsche ein.
              <br/>
              <br/>
              Übersicht zur Behandlung mit Hyaluronsäure:
              <ul>
                <li>Schmerz: gering, bei Bedarf kann eine Salbe mit einem Betäubungsmittel vorher aufgetragen werden</li>
                <li>Gesellschaftsfähig: in der Regel sofort (wobei es in den ersten Tagen zu einer leichten Schwellung kommen kann)</li>
                <li>Sichtbares Ergebnis: sofort</li>
                <li>Wirkdauer: je nach Filler 3-9 Monate </li>
              </ul>
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

              Mesotherapie

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
              Die ästhetische Mesotherapie verwendet Wirkstoffkombinationen überwiegend auf natürlicher Basis aus Antioxidantien, Vitaminen, Mineralien und Aminosäuren. Sie ist sanft, schonend, gut verträglich und kostengünstiger als die Verfahren mit Hyaluron oder Botox©.
              <br/>
              <br/>
              Die Vitalstoffcocktails werden durch Mikroinjektionen oberflächlich nur wenige mm tief in die Haut gebracht. Die dabei verwendeten Nadeln sind extrem dünn und besonders geschliffen.
              Die Hautdepots geben ihre regenerativen Wirkstoffe nach und nach ab. So kann trotz geringer Dosierung eine langanhaltende Wirkung erzeugt werden. 
              <br/>
              <br/>
              In der Regel sind 3-5 Anwendungen mit einem wöchentlichen Abstand notwendig. 
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

              PRP Eigenbluttherapie

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
              Diese natürliche Verjüngungskur der Haut ist eine Unterspritzung mit körpereigenem, aufbereitetem Plasma in das betroffene Gebiet (Gesicht, Hals und Dekolleté). Hier führt es zu einer vermehrten Produktion von hautstraffendem Elastin, Kollagen und Hyaluron. Ganz ohne Operation können so auf schmerzarme Weise Alterungsprozesse der Haut verlangsamt und ein deutlich frischeres Aussehen erzielt werden. Es verschwinden die müden Augen, der fahle Gesichtsteint und es entsteht neue Spannkraft und eine straffere Haut.
              <br/>
              <br/>
              Die Therapie wird 2-3 Mal im Abstand von 4 Wochen durchgeführt und hält ca. 1 Jahr.
              <br/>
              <br/>
              Gesicht: Vampirlifting/Faltenbehandlung mit Eigenblut
              <br/>
              <br/>
              Kopfhaut: Bei beginnendem Haarverlust ist PRP eine gut wirksame und vorbeugende Behandlungsmöglichkeit.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <Accordion>
        </Accordion>*/}
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_17} title="Botulinumtoxin" subtitle="Botox©" flipHint={true}>
          <div>
              Wir haben eine natürliche Mimik wie Stirnrunzeln, Grübeln oder Blinzeln bei Sonneneinstrahlung. Im Laufe der Jahre führt dieser ständige Muskelzug anfangs zu Linien, später entstehen Falten.
              <br/>
              <br/>
              Mit Botox© können diese mimischen Falten der oberen Gesichtshälfte geglättet werden.
              <br/>
              <br/>
              <Link className="whiteLinkText" to="/behandlung/botox">Weiter lesen ...</Link>
              </div>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_4} title="Hyperhidrosis" subtitle="Endlich weniger schwitzen" flipHint={false}>
            <p>
              Schwitzen ist ein natürlicher, lebensnotwendiger Prozess der Wärmeregulation des Körpers.
              Die Hyperhidrose bezeichnet ein unkontrollierbares, übermäßiges Schwitzen. Diese Fehlfunktion betrifft vorrangig die Schweißdrüsen von Achselhöhlen und Handinnenflächen/ Fußsohlen.
              <br/>
              <br/>
              Da die Schweißproduktion nerval gesteuert wird, kann eine Behandlung mit Botox© diese sehr effektiv reduzieren. Botox© hemmt die Reizübertragung von der  Nervenzelle zur Schweißdrüse. 
              <br/>
              <br/>
              <Link className="whiteLinkText" to="/behandlung/hyperhidrosis">Weiter lesen ...</Link>            
            </p>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_14} title="Hyaluronsäure" subtitle="Filler" flipHint={false}>
            <div>
              Mit dem Alter verliert die Gesichtshaut Kollagen, Fett, Elastin und Hyaluron. Es entsteht ein Volumenverlust und ein Nachlassen der Spannkraft.
              <br/>
              <br/>
              Hyaluronsäure ist ein wasserbindender und körpereigener Bestandteil der Haut. Durch die Unterspritzung mit Hyaluronsäure entsteht ein Hebeeffekt der Falten und Gesichtsareale. So können Konturen wiederhergestellt und eine Straffung des Gesichts erzielt werden. Der Behandlungseffekt ist sofort sichtbar und minimal-invasiv.
              <br/>
              <br/>
              <Link className="whiteLinkText" to="/behandlung/filler">Weiter lesen ...</Link>
            </div>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_2} title="Mesotherapie" subtitle="Vitalstoffe für die Haut" flipHint={false}>
            <p>
              Die ästhetische Mesotherapie verwendet Wirkstoffkombinationen überwiegend auf natürlicher Basis aus Antioxidantien, Vitaminen, Mineralien und Aminosäuren. Sie ist sanft, schonend, gut verträglich und kostengünstiger als die Verfahren mit Hyaluron oder Botox©.
              <br/>
              <br/>
              <Link className="whiteLinkText" to="/behandlung/mesotherapie-aesthetik">Weiter lesen ...</Link>
            </p>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_8} title="PRP Eigenbluttherapie" subtitle="Alterungsprozesse der Haut verlangsamen" flipHint={false}>
            <p>
              Diese natürliche Verjüngungskur der Haut ist eine Unterspritzung mit körpereigenem, aufbereitetem Plasma in das betroffene Gebiet (Gesicht, Hals und Dekolleté). Hier führt es zu einer vermehrten Produktion von hautstraffendem Elastin, Kollagen und Hyaluron.
              <br/>
              <br/>
              <Link className="whiteLinkText" to="/behandlung/eigenblut-aesthetik">Weiter lesen ...</Link>
            </p>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_24} title="Haarausfall" subtitle="Mesohair/PRP" flipHint={false}>
            <p>
            Haarverlust ist bei Frauen und Männern in einem begrenzten Umfang ein natürlicher Prozess. Bei übermäßigem Haarausfall oder Haarverlust stellen sowohl die Mesotherapie als auch das PRP Verfahren hervorragende Behandlungsoptionen dar, um den Prozess aufzuhalten bzw. zu stoppen und Haarwachstum anzuregen.
            <br/>
            <br/>
            Die Behandlung ist für Männer und Frauen mit hormonellem oder erblich bedingtem Haarausfall gleich gut geeignet, unabhängig vom Alter.
            <br/>
            <br/>
            <Link className="whiteLinkText" to="/behandlung/haarausfall">Weiter lesen ...</Link>
            </p>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
  )
      };

export default LeistungenPage;
